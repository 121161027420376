/* *********************************************************** */
/* *********************************************************** */
/* ***                                                     *** */
/* ***  FOR MORE INFORMATION, GO TO https://csslayout.io/  *** */
/* ***                                                     *** */
/* *********************************************************** */
/* *********************************************************** */






/* ---------------------------------------------------------- */
/* --------------------- FLOATING LABEL --------------------- */
/* ---------------------------------------------------------- */

.p-floating-container {
    position: relative;
}

.p-floating-container label {
    /* Position the label */
    left: 8px;
    position: absolute;
    top: 0;
    /* Hide it by default */
    opacity: 0;
    transition: 'all 200ms',
}

/* Show the label at desired position when the placeholder of input isn't shown */

.p-floating-container input:not(:placeholder-shown)+label {
    background: #FFF;
    transform: translate(0, -50%);
    opacity: 1;
}