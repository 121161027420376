@font-face {
  font-family: TW-Regular;
  src: url('./assets/fonts/TitilliumWeb-Regular.ttf');
}

@font-face {
  font-family: TW-SemiBold;
  src: url('./assets/fonts/TitilliumWeb-SemiBold.ttf');
}

@font-face {
  font-family: TW-Bold;
  src: url('./assets/fonts/TitilliumWeb-Bold.ttf');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* --- CUSTOM STYLES --- */
  color: #211A1D;
  font-family: TW-Regular;
  font-size: 15px;
}

code {}

select {
  display: block;
  width: fit-content;
  color: #575757;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e5e5ea;
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
  border-radius: 6px;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  height: 45px;
}

span {
  padding: 0 20px;
  text-align: center;
  margin: 10px;
}

.container {
  width: 100vw;
  /* height: 100vh; */
  height: fit-content;
  padding: 20px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    width: 100vw;
    /* height: 100vh; */
    padding: 20px 10vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1920px) {
  .container {
    width: 100vw;
    /* height: 100vh; */
    padding: 10px 20vw;
  }
}

@media screen and (min-width: 1921px) {
  .container {
    width: 100vw;
    /* height: 100vh; */
    padding: 30px 30vw;
  }
}

.profile-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.profile-avatar-edit-btn {
  background-color: #c3c3c3;
  width: 30px;
  height: 30px;
  position: relative;
  left: 30px;
  bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

#mlp-links-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

#mlp-add-link {
  /* background: #ccc; */
  background-color: #c3c3c3;
  width: 30px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.img-profile-avatar,
.rs-avatar-image {
  background-color: #969696;
  width: 100px !important;
  height: 100px !important;
  border: 1px solid #c3c3c3;
}

input,
button {
  height: 45px;
  font-size: inherit !important;
}

/* FORM */

.form {
  width: 100%;
}

.form-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
}

/* --- MODAL --- */

.modal {
  position: fixed;
  background: #00000059;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  left: 0;
  top: 0;
}

.modal-card {
  background-color: #fff;
  width: calc(100% - 80px);
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

@media screen and (min-width: 1000px) {
  .modal-card {
    width: 50%;
  }
}

.img-vector {
  margin: 10px;
}

.content-title,
.modal-title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.content-info {
  text-align: center;
  font-size: inherit;
  margin: 10px 0;
}

.modal-section {
  margin: 10px 0;
  text-align: center;
  font-size: inherit;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-footer {
  margin: 10px 0;
  text-align: center;
  font-size: inherit;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}


.img-profile-avatar,
.rs-avatar-image {
  object-fit: cover !important;
}