/* ***************************************************************************** */

/* ***************************************************************************** */

/* ******                                                                 ****** */

/* ******  FOR MORE INFORMATION, GO TO https://rsuitejs.com/guide/themes  ****** */

/* ******                                                                 ****** */

/* ***************************************************************************** */

/* ***************************************************************************** */

button{
    /* text-transform: capitalize; */
    font-weight: 600 !important;
    font-family: TW-Bold;
}

.primary-btn {
    background: #FFBC42;
    color: #fff;
}

.secondary-btn {
    background: transparent;
    color: #211A1D;
    text-decoration: underline;
}

.ghost-btn {
    background: #fff;
    color: #211A1D;
    border: 1px solid #211A1D;
}