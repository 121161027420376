#tks-container{
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}


#tks-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#tks-section img {
  width: 220px;
}

#thanks-redirect{
  margin: 30px 0;
  font-weight: 600;
}

#thanks-counter{
  text-align: center;
  font-size: 18px;
}